import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import './ContactFormStyles.scss'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])).join("&");
}

class ContactForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: ""
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then((r) => {
        navigate('/contact-success', { state: { name: this.state.name } })
      })
      .catch(error => alert(error))
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const { name, email, message } = this.state
    const { messageComponentsArr } = this.props
    return (
      <form className="contact-form" onSubmit={this.handleSubmit}>
        { messageComponentsArr }
        <div className="input-and-label">
          <label>
            Name:
          </label>
          <input 
            type="text" 
            name="name" 
            value={name} 
            onChange={this.handleChange} 
            required 
          />
        </div>
        <div className="input-and-label" >
          <label htmlFor="email">
            Email:
          </label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={email} 
            onChange={this.handleChange} 
            required 
          />
        </div>
        <div className="input-and-label">
          <label htmlFor="message">
            Message:
          </label>
          <div className="textarea-bg">
            <textarea
              id="message"
              name="message" 
              value={message} 
              onChange={this.handleChange}
              rows={8}
              required 
            />
          </div>
        </div>
        <button type="submit">Submit</button>
      </form>
    )
  }
}

ContactForm.defaultProps = {
  messageComponentsArr: null
}

ContactForm.propTypes = {
  messageComponentsArr: PropTypes.node
}

export default ContactForm