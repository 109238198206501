import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { buildComponentsFromHtmlAst } from '../utils/helpers'
import { ColorType } from '../utils/color'

import Layout from '../components/layout/Layout'
import SEO, { SeoType } from '../components/seo'

import PageHeader from '../components/pageHeader/PageHeader'
import ContactForm from '../components/contactForm/ContactForm'
import ContactInfo from '../components/contactInfo/ContactInfo'
import RiseUp from '../components/riseUp/RiseUp'

import './styles/ContactPageStyles.scss'

const ContactPage = (props) => {
  const { 
    title, 
    navbar,
    pageIntroText,
    seo,
    contactInfoBackgroundColor,
    contactInfoTextColor,
    email,
    websiteUrl,
    phoneNumber
  } = props.data.contentfulContactPage
  return (
    <Layout navbarData={navbar}>
      <SEO { ...seo } />
      <PageHeader title={title} />
      <div className="section contact-page">
        <div className="content-wrapper">
          <div className="form-and-info">
          <RiseUp delay={300}>
            <ContactForm messageComponentsArr={pageIntroText ? buildComponentsFromHtmlAst(pageIntroText.childMarkdownRemark.htmlAst.children) : null} className="contact-form"/>
          </RiseUp>
            <div className="contact-info-wrapper">
              <ContactInfo title="Our Info" bgColor={contactInfoBackgroundColor} textColor={contactInfoTextColor} email={email} websiteUrl={websiteUrl} phoneNumber={phoneNumber} />
              <div className="take-space" />
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    contentfulContactPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      seo: SeoType.isRequired,
      contactInfoBackgroundColor: ColorType.isRequired,
      contactInfoTextColor: ColorType.isRequired,
      email: PropTypes.string.isRequired,
      websiteUrl: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
    })
  }).isRequired
}

export const query = graphql`
  query ContactPageQuery {
    contentfulContactPage {
      title
      navbar {
        ...NavbarFragment
      }
      pageIntroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      seo {
        ...SeoFragment
      }
      contactInfoBackgroundColor {
        ...ColorFragment
      }
      contactInfoTextColor {
        ...ColorFragment
      }
      email
      websiteUrl
      phoneNumber
    }
  }
`

export default ContactPage