import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colorToStr, ColorType } from '../../utils/color'

import './ContactInfoStyles.scss'

import { formatPhoneNumber } from '../../utils/helpers'
import RiseUp from '../riseUp/RiseUp'

class ContactInfo extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      noWidth: true,
      noHeight: true,
    }
  }

  componentDidMount() {
    this.timeouts = []
    let t1 = setTimeout(() => {
      this.setState({noWidth: false})
    }, 100)
    let t2 = setTimeout(() => {
      this.setState({noHeight: false})
    }, 700)

    this.timeouts.push(t1)
    this.timeouts.push(t2)
  }

  componentWillUnmount() {
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
  }

  render() {
    const { email, websiteUrl, phoneNumber, title, bgColor, textColor } = this.props
    const { noWidth, noHeight } = this.state
    let classes = "contact-info"
    if (noWidth) {
      classes += " no-width"
    }
    if (noHeight) {
      classes += " no-height"
    }
    return (

      <div className={classes}>
        <div className="horiz-animate-flex">
          <div className="take-space" />
          <div className="info-wrapper" style={{ backgroundColor: `${colorToStr(bgColor.color[0])}`, color: `${colorToStr(textColor.color[0])}` }}>
            <div className="info">
              <RiseUp delay={1400}>
                <h2>{ title }</h2>
                <p>
                  <a href={`mailto:${email}`}>
                    <FontAwesomeIcon className="contact-line-icon" icon="envelope" />
                    <span>{email}</span>
                  </a>
                </p>
                <p>
                  <a href={websiteUrl}>
                    <FontAwesomeIcon className="contact-line-icon" icon="globe" />
                    <span>{websiteUrl}</span>
                  </a>
                </p>
                <p>
                  <a href={`tel:${phoneNumber}`}>
                    <FontAwesomeIcon className="contact-line-icon" icon="phone" />
                    <span>{formatPhoneNumber(phoneNumber)}</span>
                  </a>
                </p>
              </RiseUp>
            </div>
          </div>
        </div>
        <div className="take-space" />
      </div>
    )
  }
}

ContactInfo.defaultProps = {
  title: "Our Info"
}

ContactInfo.propTypes = {
  email: PropTypes.string.isRequired,
  websiteUrl: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  bgColor: ColorType.isRequired,
  textColor: ColorType.isRequired,
  title: PropTypes.string,
}


export default ContactInfo